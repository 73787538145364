import Axios from 'axios'
import qs from 'qs'
import { Message, Loading } from 'element-ui';
import store from '@/store'
import router from '@/router'
import {route} from '@/api/apiRoute'
let baseURL =route;

let loading;        //定义loading变量

function startLoading() {    //使用Element loading-start 方法
    loading = Loading.service({
        lock: true,
        text: '加载数据中……',
        background: 'rgba(0, 0, 0, 0.3)'
    })
}
function endLoading() {    //使用Element loading-close 方法
    loading.close()
}
//那么 showFullScreenLoading() tryHideFullScreenLoading() 要干的事儿就是将同一时刻的请求合并。
//声明一个变量 needLoadingRequestCount，每次调用showFullScreenLoading方法 needLoadingRequestCount + 1。
//调用tryHideFullScreenLoading()方法，needLoadingRequestCount - 1。needLoadingRequestCount为 0 时，结束 loading。
let needLoadingRequestCount = 0
export function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        startLoading()
    }
    needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return
    needLoadingRequestCount--
    if (needLoadingRequestCount === 0) {
        endLoading()
    }
}
 

Axios.defaults.timeout = 50000;
Axios.defaults.withCredentials = false;
// Axios.defaults.withCredentials=true;//设置发送请求时带上cookie


//http request 拦截器
Axios.interceptors.request.use(
  config => {
    if (localStorage.loginType == 2) {
      baseURL = 'https://scm-qzc-api.cs4pl.com';
    }
    config.url = baseURL + config.url
    if(config.method === 'post') {
      config.data = qs.stringify(config.data);
      config.headers = {
        'Authorization' : store.state.token,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    }if(config.method === 'putformdata') {
      config.method = 'put';
      config.data = qs.stringify(config.data);
      config.headers = {
        'Authorization' : store.state.token,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    }else if (config.method === 'postsign') {
      // console.log('canshu',config.data);
      config.method = 'post';
      config.headers = {
        sign: config.data.sign,
        timeStamp: config.data.timeStamp,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      };
    }else if(config.method === 'deletes') { 
      config.method = 'delete';
      config.headers = {
        'Authorization' : store.state.token,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    }else if(config.method === 'postimage') { 
      config.method = 'post';
      config.headers = {
        'Authorization' : store.state.token,
        'Content-Type': 'multipart/form-data'
      }
    }else if(config.method === 'postfile') { 
      config.method = 'post';
      config.headers = {
        'Authorization' : store.state.token,
        'Content-Type': 'multipart/form-data'
      };
      config.timeout = 60*60*24*1000
    }else if(config.method === 'postjson') {
      config.method = 'post';
       config.data = JSON.stringify(config.data); 
      config.headers = {
        'Authorization' : store.state.token,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    }else if(config.method === 'postjsond') { 
       config.method = 'post'; 
       config.data = JSON.stringify(config.data);
       config.headers = {
        'Authorization' : store.state.token,
        'Content-Type': 'application/json;charset=UTF-8', 
      }; 
    }else if(config.method === 'postcan') {
      config.method = 'post';
       // config.data = JSON.stringify(config.data); 
      config.headers = {
        'Authorization' : store.state.token,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    }else if(config.method === 'putnoparams') {
      config.method = 'put';
      // config.data = qs.stringify(config.data);
      config.headers = {
        'Authorization' : store.state.token,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    }else if(config.method === 'put') {
      // config.method = 'post';
       config.data = JSON.stringify(config.data); 
      config.headers = {
        'Authorization' : store.state.token,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    }else if(config.method === 'putform') {
      config.method = 'put';
       // config.data = JSON.stringify(config.data); 
      config.headers = {
        'Authorization' : store.state.token,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    }else if(config.method === 'get') {
      let newParams = {}
      for(let key in config.params) {
        newParams[key] = encodeURIComponent(config.params[key])
      }
      config.params = newParams
 
      config.headers = {
         'Authorization' : store.state.token,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    }
    
    //显示等待框
    //showFullScreenLoading()

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

//http response 拦截器
// 响应时
// Axios.interceptors.response.use(response => response, err => {
//     // Message.error({
//     //   content:err
//     // })
//     return Promise.resolve(err.response)

//   }
// )
// Axios.interceptors.response.use(
//   response =>{
//     return response;
//   },
//   error=>{
//     if(error.response){
//       switch(error.response.status){
//         case 401:
//           localStorage.removeItem('token'); 
//      /*     router.push('/views/login');*/
//           router.replace({
//             path: '/Login',
//             // query: {redirect: router.currentRoute.fullPath}//登录成功后跳入浏览的当前页面
//           })
//       }
//     }
 
//   }
// )
Axios.interceptors.response.use(
  response =>{ 
    return response;
  },
  error=>{ 
    Message.error(error.response.data.msg);
    if (error.response.data && error.response.data.msg.indexOf('已过期') > -1) {
      localStorage.clear();
      location.href = '/'
    }
 
  }
)
// Axios.interceptors.response.use(
//   response => {
//     let data = response.data
//     console.log(data);
//     //隐藏等待框
//     //tryHideFullScreenLoading()

//     if(data.successFlag === 1) {
//       return Promise.resolve(data)
//     } else {
//       return Promise.reject(data)
//     }
//   },
//   error => {
//     return Promise.reject(error)
//   }
// )

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

const get = function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    params.showLoading && showFullScreenLoading() //显示等待框

    Axios.get(url, {
        params: params
      })
      .then(response => {
        params.showLoading && tryHideFullScreenLoading() //隐藏等待框

        resolve(response);
      })
      .catch(err => {
        params.showLoading && tryHideFullScreenLoading() //隐藏等待框

        reject(err)
      })
  })
}
 

/**
 * 封装post请求
 * @param url
 * @param params
 * @returns {Promise}
 */

const post = function post(url, params = {}) {
  return new Promise((resolve, reject) => {
    params.showLoading && showFullScreenLoading() //显示等待框

    Axios.post(url, params)
      .then(response => {
        params.showLoading && tryHideFullScreenLoading() //隐藏等待框

        resolve(response);
      }, err => {
        params.showLoading && tryHideFullScreenLoading() //隐藏等待框

        reject(err)
      })
  })
}


/**
 * 封装post请求
 * @param url
 * @param params
 * @returns {Promise}
 */

const putFormData = function putFormData(url, params = {}) {
  return Axios({
      method:'putFormData',
      data:params,
      url
    })
  return new Promise((resolve, reject) => {
    params.showLoading && showFullScreenLoading() //显示等待框

    Axios.put(url, params)
      .then(response => {
        params.showLoading && tryHideFullScreenLoading() //隐藏等待框

        resolve(response);
      }, err => {
        params.showLoading && tryHideFullScreenLoading() //隐藏等待框

        reject(err)
      })
  })
}

/**
 * 封装postImage请求
 * @param url
 * @param params
 * @returns {Promise}
 */

const postImage = function postImage(url, params = {}) {
  return Axios({
      method:'postImage',
      data:params,
      url
    })

  return new Promise((resolve, reject) => {
    params.showLoading && showFullScreenLoading() //显示等待框
  
    Axios.post(url, params)
      .then(response => {
        params.showLoading && tryHideFullScreenLoading() //隐藏等待框

        resolve(response);
      }, err => {
        params.showLoading && tryHideFullScreenLoading() //隐藏等待框

        reject(err)
      })
  })
}


/**
 * 封装postImage请求
 * @param url
 * @param params
 * @returns {Promise}
 */

const postFile = function postImage(url, params = {}) {
  return Axios({
      method:'postFile',
      data:params,
      url
    })

  return new Promise((resolve, reject) => {
    params.showLoading && showFullScreenLoading() //显示等待框
  
    Axios.post(url, params)
      .then(response => {
        params.showLoading && tryHideFullScreenLoading() //隐藏等待框

        resolve(response);
      }, err => {
        params.showLoading && tryHideFullScreenLoading() //隐藏等待框

        reject(err)
      })
  })
}

/**
 * 封装post请求
 * @param url
 * @param params
 * @returns {Promise}
 */

const postJson = function postJson(url, params = {}) {
  return Axios({
      method:'postJson',
      data:params,
      url
    })

  return new Promise((resolve, reject) => {
    params.showLoading && showFullScreenLoading() //显示等待框
  
    Axios.post(url, params)
      .then(response => {
        params.showLoading && tryHideFullScreenLoading() //隐藏等待框

        resolve(response);
      }, err => {
        params.showLoading && tryHideFullScreenLoading() //隐藏等待框

        reject(err)
      })
  })
}


/**
 * 封装post请求 文件流解析
 * @param url
 * @param params
 * @returns {Promise}
 */

const postJsonD = function postJsonD(url, params = {}) {
  return Axios({
      method:'postJsonD',
      data:params,
      url,
      responseType:'arraybuffer',
      // withCredentials: true,
    })

  return new Promise((resolve, reject) => {
    params.showLoading && showFullScreenLoading() //显示等待框
  
    Axios.post(url, params)
      .then(response => {
        params.showLoading && tryHideFullScreenLoading() //隐藏等待框

        resolve(response);
      }, err => {
        params.showLoading && tryHideFullScreenLoading() //隐藏等待框

        reject(err)
      })
  })
}

/**
 * 封装postCan请求 不带参数
 * @param url
 * @param params
 * @returns {Promise}
 */

const postCan = function postCan(url, params = {}) {
  return Axios({
      method:'postCan',
      data:params,
      url
    })

  return new Promise((resolve, reject) => {
    params.showLoading && showFullScreenLoading() //显示等待框
  
    Axios.post(url, params)
      .then(response => {
        params.showLoading && tryHideFullScreenLoading() //隐藏等待框

        resolve(response);
      }, err => {
        params.showLoading && tryHideFullScreenLoading() //隐藏等待框

        reject(err)
      })
  })
}

/**
 * 封装put请求
 * @param url
 * @param params
 * @returns {Promise}
 */

 const putNoParams = function putNoParams(url, params = {}) {
  return Axios({
      method:'putNoParams',
      data:params,
      url
    })
  return new Promise((resolve, reject) => {
    params.showLoading && showFullScreenLoading() //显示等待框

    Axios.put(url, params)
      .then(response => {
        params.showLoading && tryHideFullScreenLoading() //隐藏等待框

        resolve(response);
      }, err => {
        params.showLoading && tryHideFullScreenLoading() //隐藏等待框

        reject(err)
      })
  })
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

const patch = function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    Axios.patch(url, data)
      .then(response => {
        resolve(response);
      }, err => {
        reject(err)
      })
  })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

const put = function put(url, data = {}) {
  return Axios({
      method:'put',
      data:data,
      url
    })
  return new Promise((resolve, reject) => {
    Axios.put(url, data)
      .then(response => {
        resolve(response);
      }, err => {
        reject(err)
      })
  })
}


const putform = function put(url, data = {}) {
  return Axios({
      method:'putform',
      data:data,
      url
    })
  return new Promise((resolve, reject) => {
    Axios.put(url, data)
      .then(response => {
        resolve(response);
      }, err => {
        reject(err)
      })
  })
}

/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */

const deletes = function deletes(url, params = {}) {
  return Axios({
      method:'deletes',
      data:params,
      url
    })
  return new Promise((resolve, reject) => {
    Axios.delete(url, params)
      .then(response => {
        resolve(response);
      }, err => {
        reject(err)
      })
  })
}

const postSign = function post(url, params = {}) {
  return Axios({
    method: 'postsign',
    data: params,
    url,
  });
  return new Promise((resolve, reject) => {
    params.showLoading && showFullScreenLoading(); //显示等待框

    Axios.post(url, params).then(
      response => {
        params.showLoading && tryHideFullScreenLoading(); //隐藏等待框

        resolve(response);
      },
      err => {
        params.showLoading && tryHideFullScreenLoading(); //隐藏等待框

        reject(err);
      },
    );
  });
};


export default {
  postSign,
  post,
  get,
  patch,
  put,
  postJson, 
  deletes,
  postImage,
  postCan,
  putform,
  postFile,
  putFormData,
  postJsonD,
  putNoParams
}
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const key = 'token';
const guid = 'guid';
const userId = 'userId';
const store = new Vuex.Store({
  state() {
    return {
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      guid: localStorage.getItem('guid') ? localStorage.getItem('guid') : '',
      userId: localStorage.getItem('userId') ? localStorage.getItem('userId') : '',
      medGuid: 'd2256eb2892d4887a02c6ef9a4beeffb',
    };
  },
  getters: {
    getSortage: function(state) {
      if (!state.token) {
        state.token = JSON.parse(localStorage.getItem(key));
      }
      return state.token;
    },
    getTenantGuid: function(state) {
      if (!state.guid) {
        state.guid = JSON.parse(localStorage.getItem(guid));
      }
      return state.guid;
    },
    getTenantUserId: function(state) {
      if (!state.userId) {
        state.userId = JSON.parse(localStorage.getItem(userId));
      }
      return state.userId;
    },
    // getTenantGuid: state => state.guid
  },
  mutations: {
    $_setStorage(state, value) {
      state.token = value;
      localStorage.setItem(key, value);
    },
    $_removeStorage(state) {
      state.token = null;
      localStorage.removeItem(key);
    },
    $_setTenantGuid(state, value) {
      // console.log(guid);
      state.guid = value;
      localStorage.setItem(guid, value);
    },
    $_setUserId(state, value) {
      // console.log(guid);
      state.userId = value;
      localStorage.setItem(userId, value);
    },
  },
});
export default store;

<template>
  <div class="home">
    <div class="topHeader">
      <img class="newbg" src="../assets/newbg.png" />
    </div>
    <div class="topMenu">
      <img class="menbg" src="../assets/menu_bg.png" alt="" />
      <section class="four-terminal">
        <p class="item" @click="tolink('药企专用平台', 1)">
          <img class="no-active" src="../assets/btn1.png" width="100%" height="100%" alt="" srcset="" />
          <img class="active" src="../assets/btn1_hover.png" width="100%" height="100%" alt="" srcset="" />
        </p>
      
        <p class="item" @click="tolink('终端服务平台', 3)">
          <img class="no-active" src="../assets/btn3.png" width="100%" height="100%" alt="" srcset="" />
          <img class="active" src="../assets/btn3_hover.png" width="100%" height="100%" alt="" srcset="" />
        </p>
        <p class="item" @click="tolink('供应链平台', 2)">
          <img class="no-active" src="../assets/btn2.png" width="100%" height="100%" alt="" srcset="" />
          <img class="active" src="../assets/btn2_hover.png" width="100%" height="100%" alt="" srcset="" />
        </p>
        <p class="item" @click="toOperate">
          <img class="no-active" src="../assets/btn4.png" width="100%" height="100%" alt="" srcset="" />
          <img class="active" src="../assets/btn4_hover.png" width="100%" height="100%" alt="" srcset="" />
        </p>
      </section>
    </div>
    <div class="poster">
      <img src="../assets/poster.png" alt="" />
    </div>
    <div class="solve">
      <img src="../assets/poster2.png" alt="" />
    </div>
    <div class="case">
      <h1 style="text-align:center;"><img  style="width:6%;margin-top:52px;"  src="../assets/case_title.png" alt=""></h1>
      <div class="tab" style="box-shadow: 0px 42px 32px rgba(211, 211, 211, 0.13)">
        <div :class="tab == 1 ? 'active' : ''" @click="tab = 1">可信</div>
        <div :class="tab == 2 ? 'active' : ''" @click="tab = 2">合规</div>
        <div :class="tab == 3 ? 'active' : ''" @click="tab = 3">降本</div>
        <div :class="tab == 4 ? 'active' : ''" @click="tab = 4">提效</div>
        <div :class="tab == 5 ? 'active' : ''" @click="tab = 5">增额</div>
        <div :class="tab == 6 ? 'active' : ''" @click="tab = 6">可视</div>
      </div>
      <div>
        <img src="../assets/msg_tab1.png" alt="" v-show="tab == 1" />
        <img src="../assets/msg_tab2.png" alt="" v-show="tab == 2" />
        <img src="../assets/msg_tab3.png" alt="" v-show="tab == 3" />
        <img src="../assets/msg_tab4.png" alt="" v-show="tab == 4" />
        <img src="../assets/msg_tab5.png" alt="" v-show="tab == 5" />
        <img src="../assets/msg_tab6.png" alt="" v-show="tab == 6" />
      </div>
    </div>
    <div >
      <img   style="width:100%;" src="../assets/poster3.png" alt="" />
    </div>
    <div class="footer">
      <div class="footInfo">
        <img src="../assets/fbtLogo1.png" />
        <div class="info">
          <div>商务联系人：</div>
          <div>
            <p>
              <span>王先生</span>
              <span style="margin-left: 112px">电话：139-1088-9337</span>
              <span style="margin-left: 112px">邮箱：wangwei@csbr.cn</span>
            </p>
            <p>
              <span>曹先生</span>
              <span style="margin-left: 112px">电话：188-7223-0383</span>
              <span style="margin-left: 112px">邮箱：weicao@csbr.cn</span>
            </p>
          </div>
        </div>
      </div>
      <div class="address">
        <p style="width: 108px; text-align: justify">
          地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址：
        </p>
        <p>
          北京市西城区宣武门外大街庄胜广场办公楼西翼1410室<br />
          武汉市江汉区武汉商务区泛海国际SOHO城7栋901室
        </p>
      </div>
      <p class="banquan" @click="toLink">北京传世博润科技有限公司 Copyright © 2016-2024 京ICP备2024044205号</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'home',
  data() {
    return {
      tab: 1,
    };
  },
  methods: {
    toLink(){
      location.href = 'https://beian.miit.gov.cn/'
    },
    toOperate() {
      window.open(location.origin +`/operationLogin` );
      // var url;
      // var env = window.env;
      // if (env === 'pro') {
      //   //生产
      //   //url = 'https://scm-operation.csyaoly.com/Login?1';
      //   url = 'https://scm-operation.cs4pl.com/Login?1';
      // } else if (env === 'sit') {
      //   //测试
      //   url = `https://scm-operation-test.csbr.cn/Login?1`;
      // } else if (env === 'pre') {
      //   //pre
      //   url = 'https://scm-operation.csyaoly.com/Login?1';
      // } else {
      //   //开发
      //   url = `http://localhost:8086`;
      // }
      // window.open(url);
    },
    tolink(logoTitle, type) {
      let descData;
      if (type == 1) {
        descData = {
          loginType:type,
          logoTitle: logoTitle,
          title: 'FBT药企一站式服务平台<div style="font-size:18px;">为药企提供营销与供应链数字化解决方案</div>',
          content:
            '传世关注工业企业的销售与供应链环节，通过提供信息化产品与运营服务，将销售、商务、市场、仓储、配送等业务实现线上化、平台化，帮助企业实现数字化转型。',
        };
      }
      if (type == 2) {
        descData = {
          loginType:type,
          logoTitle: logoTitle,
          title:
            '四位一体的供应链平台<div style="font-size:15px;font-weight:bold;">OMS/订单管理、TPL/三方物流、TMS/配送管理、MWS/多仓调度</div>',
          content:
            '平台可以提供什么？<div>-提供物流资源管理、前置仓开仓与管理服务；</div><div>-帮助企业优化供应链成本，提高终端服务时效与质量。</div>',
        };
      }
      if (type == 3) {
        descData = {
          loginType:type,
          logoTitle: logoTitle,
          title: '终端服务平台',
          content:
            '<div>-打造更符合医药行业的CRM系统移动办公，简化人员工作流程，提升各环节工作效率；</div><div>-员工管理有据可依，定向优化团队工作效能，销售日常工作行为智能分析。</div>',
        };
      }
      localStorage.setItem('descData',JSON.stringify(descData))
      window.open(location.origin +`/login` );
    },
  },
  mounted() {
    localStorage.clear();
  },
};
</script>
<style scoped>
.cur-po {
  cursor: pointer;
}
.system-entry {
  width: 200px;
  height: 40px;
  text-align: center;
  font-weight: bold;
  border-radius: 5px;
  color: #222;
  background: rgb(246, 212, 140);
  line-height: 40px;
  font-size: 18px;
  margin-top: 35px;
  cursor: pointer;
}
.topHeader {
  position: relative;
}

.topHeader .newbg {
  width: 100%;
}

.topHeader .topNav {
  position: absolute;
  top: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 107px;
  padding: 0 5%;
}

.topHeader .topNav .left {
  height: 35px;
}

.topHeader .topNav .right {
  display: flex;
  align-items: center;
}

.topHeader .topNav .right img {
  height: 36px;
}

.topHeader .topNav .right a {
  margin-left: 82px;
  color: #fff;
  font-size: 18px;
  text-decoration: none;
}

.topHeader .topText {
  position: absolute;
  top: 120px;
  z-index: 9;
  box-sizing: border-box;
  width: 100%;
  padding-left: 15%;
  color: #fff;
  text-align: left;
}

.topHeader .topText h3 {
  margin: 0;
  font-weight: normal;
  font-size: 42px;
}

.topHeader .topText span {
  font-size: 62px;
}

.topHeader .topText p {
  margin-top: 20px;
  font-size: 18px;
  line-height: 36px;
}

.topHeader .topEntry {
  position: absolute;
  bottom: -50px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  width: 70%;
  margin-left: 15%;
  padding: 20px;
  text-align: center;
  background-color: #3f4c5f;
  border-radius: 6px;
  display: none;
}

.topHeader .topEntry .entryItem {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-align: center;
  border-right: 2px solid #b2b2b2;
}

.topHeader .topEntry .entryItem:last-child {
  border: none;
}

.topHeader .topEntry .entryItem img {
  width: 60px;
  height: 60px;
}

.topHeader .topEntry .entryItem > div {
  display: inline-block;
  margin-left: 40px;
}

.topHeader .topEntry .entryItem p {
  font-size: 16px;
}

.topHeader .topEntry .entryItem .btn {
  margin-top: 14px;
  padding: 5px 20px;
  font-size: 13px;
  border: 1px solid #ffffff;
  border-radius: 16px;
}

.topHeader .topEntry .entryItem .btn:hover {
  color: #3f4c5f;
  background: #ffffff;
  cursor: pointer;
  opacity: 0.86;
}

.warp {
  position: relative;
  margin-top: 100px;
}
.warp .leftbg {
  position: absolute;
  top: 45%;
  left: 0;
}
.warp .rightbg {
  position: absolute;
  top: 70%;
  right: 0;
}
.warp .section1 {
  width: 60%;
  margin: 0 auto;
  color: #000000;
  font-size: 28px;
  text-align: center;
}

.warp .section1 p {
  margin-bottom: 92px;
}
.warp .section1 img {
  width: 100%;
}

.warp .section2 {
  width: 900px;
  margin: 0 auto;
}

.warp .section2 .sectionItem {
  display: flex;
  align-items: center;
  margin-top: 204px;
}

.warp .section2 .sectionItem > div {
  text-align: left;
}

.warp .section2 .sectionItem h3 {
  color: #4fa1a4;
  font-size: 30px;
}

.warp .section2 .sectionItem p {
  color: #323232;
  font-size: 16px;
  line-height: 30px;
}

.footer {
  width: 100%;
  padding: 50px 0;
  background: #3f4c5f;
}

.footInfo {
  display: flex;
  justify-content: center;
  text-align: left;
}

.footInfo img {
  height: 38px;
  margin-right: 50px;
}

.footInfo > div {
  margin-left: 15px;
}

.footInfo p {
  /* margin-bottom: 20px; */
  color: #fcfcfc;
  font-size: 18px;
  line-height: 36px;
}

.footer .banquan {
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  opacity: 0.28;
  margin-top: 48px;
  cursor: pointer;
}
.footer .footInfo .info {
  display: flex;
  color: #fcfcfc;
  font-size: 18px;
  line-height: 36px;
  padding-top: 10px;
}
.footer .address {
  text-align: left;
  color: #fcfcfc;
  font-size: 18px;
  line-height: 36px;
  display: flex;
  justify-content: center;
  margin-left: 20px;
  margin-top: 30px;
}
.topMenu {
  /* height: 222px; */
  position: relative;
}
.topMenu .menbg {
  /* height: 200px; */
  width: 100%;
}

.four-terminal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 85%;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.four-terminal .item .no-active {
  display: block;
}
.four-terminal .item .active {
  display: none;
}
.four-terminal .item:hover .active {
  display: block;
}
.four-terminal .item:hover .no-active {
  display: none;
}
.four-terminal .item:hover .active {
  display: block;
}

.four-terminal .item {
  /* background: #394d60; */
  width: 25%;
  color: #fff;
  position: relative;
  cursor: pointer;
  margin-right: 14px;
}
.four-terminal .item:first-child{
  margin-left: 14px;
}

.four-terminal .item .line {
  height: 69px;
  width: 1px;
  background: rgba(255, 255, 255, 0.3254901960784313);
  position: absolute;
  right: 0;
  top: 16px;
}

.four-terminal .item .title {
  display: flex;
  font-size: 20px;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
}

.four-terminal .item .content {
  padding-left: 26px;
  display: none;
}

.poster img {
  width: 100%;
}
.solve {
  margin-top: 50px;
  text-align: center;
}
.solve img {
  width: 62%;
  height: auto;
}
.case {
  text-align: center;
  background: #fcfcfc;
}
.case h1 {
  font-size: 25px;
  color: #292929;
  font-weight: 500;
  margin-top: 82px;
}
.case .tab {
  width: 67%;
  display: flex;
  margin: 0 auto;
  font-size: 18px;
  justify-content: space-around;
  color: #292929;
  margin-top: 52px;
}
.case .tab div {
  height: 40px;
  border-bottom: 4px solid #fcfcfc;
  padding: 0 20px;
  cursor: pointer;
}
.case .tab div.active {
  border-bottom: 5px solid #4fa1a4;
  color: #4fa1a4;
  font-weight: bold;
}
.case img {
  width: 62%;
  height: auto;
}
</style>


const serverConfig = {
  REQUIRE_SUCCESS_CODE: "00000", //请求成功的编码
  msSalesPerformService: 'ms-sales-perform-service',
  msCommonAdminCloudService: 'ms-common-admin-cloud-service',
  msCustomerService: 'ms-customer-service',
  msPersonnelService: 'ms-personnel-service',
  msCommonAdminPlatformAuthService: 'ms-common-admin-platform-auth-service',
  msConfigureService: 'ms-configure-service',
  msUserUnifiedAuthenticationService:'ms-user-unified-authentication-service',
  msPurchaseService: 'ms-purchase-service',
  msReportConfigService: 'ms-report-config-service',
  msUserRemunerationService: 'ms-user-remuneration-service',
  msAiAlgorithmService: 'ms-ai-algorithm-service',
  msProductService: 'ms-product-service',
  msSalesPerformServiceProductA:'ms-sales-perform-service-product-a',
  msBizdataStatisticsService: 'ms-bizdata-statistics-service',
  msDataBusinessService: 'ms-data-business-service',
  msCommonImportDataService: 'ms-common-import-data-service',//导入服务
  FILE_SIZE:10//上传文件限制大小
};

export default serverConfig;
// serverConfig.msAiAlgorithmService

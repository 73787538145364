import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/newHome.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/operationLogin',
    name: 'operationLogin',
    component: () => import('../views/operationLogin.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/newLogin.vue'),
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index.vue'),
  },
  {
    path: '/show',
    name: 'show',
    component: () => import('../views/show.vue'),
  },{
    path: '/KA_homePage',
    name: 'KA_homePage',
    component: () => import('../views/personalPage/KA_homePage.vue'),
  },{
    path: '/salesman_homePage',
    name: 'salesman_homePage',
    component: () => import('../views/personalPage/salesman_homePage.vue'),
  },{
    path: '/supplyChain_homePage',
    name: 'supplyChain_homePage',
    component: () => import('../views/personalPage/supplyChain_homePage.vue'),
  },{
    path: '/SZ_homePage',
    name: 'SZ_homePage',
    component: () => import('../views/personalPage/SZ_homePage.vue'),
  },
  {
    path: '/messageManagementList',
    name: 'messageManagement',
    component: () => import('../views/messageManagement/messageManagementList.vue'),
  },
  {
    path: '/SZ_homePageNew',
    name: 'SZ_homePageNew',
    component: () => import('../views/personalPage/SZ_homePageNew.vue'),
  },
  {
    path: '/SZ_homePageReport',
    name: 'SZ_homePageReport',
    component: () => import('../views/personalPage/SZ_homePageReport.vue'),
  },
  // {
  //   path: '/SZ_homePageReport',
  //   name: 'SZ_homePageReportNew',
  //   component: () => import('../views/personalPage/SZ_homePageReport.vue'),
  // },
  {
    path: '/SZ_homePageReportNew',
    name: 'SZ_homePageReportNew',
    component: () => import('../views/personalPage/SZ_homePageReportNew.vue'),
  },
  {
    path:'/marketingDigitalPlatform',  //营销数字化平台
    name:'marketingDigitalPlatform',
    component: () => import('../views/personalPage/marketingDigitalPlatform.vue'),
  },
  {
    path: '/userAgree',
    name: 'userAgree',
    component: () => import('../views/userAgree.vue'),
  },
  {
    path: '/userPrivate',
    name: 'userPrivate',
    component: () => import('../views/userPrivate.vue'),
  },
];

export default routes;


let route, websocketUrl;
// let route1 = ''
//判断开发环境or生产环境请求前缀
if (location.href.indexOf('cs4pl.com') > -1) {
    websocketUrl = 'websocket-product.cs4pl.com';//websocket正式1
    route = 'https://scmapi.csyaoly.com'; //正式1
    // websocketUrl = 'websocket-pre-product.cs4pl.com';//websocket正式2
    // route = 'https://scmpreapi.cs4pl.com';//正式2
} else {
    websocketUrl = 'websocket.cs4pl.com'//websocket测试
    route = `https://zuul.cs4pl.com`; //测试
}

export { route, websocketUrl };

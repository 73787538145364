
import moment from 'moment';

// 调用样例：{{mydate | getDate}}
// getDateStartEnd   年月日   YY-MM-DD
const getDate = val => {
  if (val == '' || val == null) {
    return '';
  } else {
    return moment(val).format('YYYY-MM-DD');
  }
};
//识别换行
const enterTxt = val => {
  // val1 = val.replace(/?/g,"<br/>");
  val = val.replace(/-/g, '<br/>-');
  return val;
};
// 调用样例：{{mydate | getDate}}
// getDateStartEnd   年月日   YY-MM-DD
const getTime = val => {
  if (val == '' || val == null) {
    return '-';
  } else {
    return moment(val).format('YYYY-MM-DD HH:mm');
  }
};
export { getDate, enterTxt,getTime };

import './public-path';

import 'element-ui/lib/theme-chalk/index.css';
import Vue from 'vue';
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import api from '@/uilt/new-axios.js'; // 此处命名为rq,你可以改
import App from './App.vue';
import routes from './router';
import '@/assets/style/style.css';
import store from './store';
import moment from 'moment';
import echarts from 'echarts';
import { UrlSearch } from './uilt/urlSearch';
import * as filter from './filter';

import md5 from 'js-md5';
// 记得改为你的路径
import serverConfig from '@/assets/global/serverConfig';
window.serverConfig = serverConfig;
Vue.prototype.api = api;
const Request = new UrlSearch();
Vue.prototype.$Request = Request;
Vue.prototype.$moment = moment;
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;
Vue.use(ElementUI);

Object.keys(filter).forEach(key => {
  Vue.filter(key, filter[key]);
});
let router = null;
let instance = null;

Vue.prototype.$md5 = md5;
window.env = location.href.indexOf('test.cs4pl.com') > -1 ? 'sit' : location.href.indexOf('cs4pl.com') > -1 ? 'pro' : 'dev'
function render(props = {}) {
  const { container } = props;
  router = new VueRouter({
    base: window.__POWERED_BY_QIANKUN__ ? '/feFbtWeb' : '/',
    mode: 'history',
    routes,
  });

  instance = new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount(container ? container.querySelector('#app') : '#app');
}

if (!window.__POWERED_BY_QIANKUN__) {
  render();
}

function storeTest(props) {
  props.onGlobalStateChange &&
    props.onGlobalStateChange(
      (value, prev) => console.log(`[onGlobalStateChange - ${props.name}]:`, value, prev),
      true,
    );
  props.setGlobalState &&
    props.setGlobalState({
      ignore: props.name,
      user: {
        name: props.name,
      },
    });
}

export async function bootstrap() {
  console.log('[vue] vue app bootstraped');
}

export async function mount(props) {
  console.log('[vue] props from main framework', props);
  storeTest(props);
  render(props);
}

export async function unmount() {
  instance.$destroy();
  instance.$el.innerHTML = '';
  instance = null;
  router = null;
}
